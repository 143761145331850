/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url("fonts/Poppins-Regular.ttf") format('truetype');
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url("fonts/Poppins-Regular.ttf") format('truetype');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url("fonts/Poppins-Regular.ttf") format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

body {
  font-family: 'Poppins', serif;
}
.text-white {
  letter-spacing: 0.025em;
}
.ti-check {
  color: #3DFFC0;
}

.bg-primary {
  background: #3DFFC0 !important;
}

.btn-primary {
  background-color: #3DFFC0;
}
.text-dark-blue {
  color: #041F3D;
}
.container-fluid {
  padding: 0;
}
.row {
  margin-left: auto;
  margin-right: auto;
}
.col {
  padding: 0;
}
.form-footer {
  margin-top: 1rem;
}
.form-control, .btn {
  border-radius: 2px;
}
.col-right {
  flex: 0 0 auto;
  width: 960px;
}

.container-right {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  max-width: 70%;
  margin-top: 10%;
}
.title {
  letter-spacing: 0.06em;
}

.flag {
  border-radius: 1px;
  border: transparent 0;
  width: 20px;
  margin-right: 7px;
}

.dropdown-menu {
  padding: 0;
  min-width: 120px;
  width: 120px;
}

.dropdown-toggle {
  border: 0;
}

.dropdown-item {
  min-width: 118px;
  width: 118px;
  border-radius: 2px 2px 0px 0px;
}

@media (min-width: 1500px) {
  .col-left {
    max-width: 50%;
  }
  .col-left-login {
    overflow: hidden !important;
  }
}

@media (max-width: 1499px) {
  .col-right {
    display: none !important;
  }

  .container-right {
    max-width: 50%;
  }
}

@media (max-width: 1000px) {
  .container-right {
    max-width: 90%;
  }
  .page {
    min-height: 90vh;
  }
}

#security-lock-icon {
  font-size: 60px
}

#security small {
    font-size: 13px;
}